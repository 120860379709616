"use client"

import { type FC, useCallback, useEffect, useMemo, useRef } from "react"

import { useSearchParams } from "next/navigation"
import { signIn, useSession } from "next-auth/react"
import { toast } from "sonner"

import { Button } from "@/components/ui/button"

import { useLoginContext } from "../LoginContext"
import { type GitHubState } from "../types"

const GITHUB_CLIENT_ID = process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID

const GitHubButton: FC = () => {
  const searchParams = useSearchParams()
  const session = useSession()
  const handledGitHubSignIn = useRef(false)
  const { signingIn, setSigningIn } = useLoginContext()

  const GITHUB_OAUTH_URL = useMemo(
    () =>
      `https://github.com/login/oauth/authorize?scope=user:email&client_id=${GITHUB_CLIENT_ID}&state=${JSON.stringify(
        {
          callbackUrl: searchParams.get("callbackUrl"),
          source: searchParams.get("source"),
          redirecturi: searchParams.get("redirecturi"),
        } as GitHubState,
      )}`,
    [searchParams],
  )

  const signInWithGitHub = useCallback(
    async (code: string) => {
      setSigningIn("github")
      const response = await signIn("github", {
        callbackCode: code,
        redirect: false,
      })

      if (response?.status !== 200) {
        setSigningIn(false)
        toast.error("Something went wrong whilst signing in with GitHub")
      }
    },
    [setSigningIn],
  )

  useEffect(() => {
    const isNotLoggedIn = session.status === "unauthenticated"

    if (
      isNotLoggedIn &&
      searchParams.has("code") &&
      handledGitHubSignIn.current === false
    ) {
      handledGitHubSignIn.current = true
      signInWithGitHub(searchParams.get("code")!)
    }
  }, [signInWithGitHub, searchParams, session.status])

  return (
    GITHUB_CLIENT_ID && (
      <Button
        type="button"
        onClick={() => {
          window.location.href = GITHUB_OAUTH_URL
        }}
        isLoading={signingIn === "github"}
        disabled={signingIn !== false}
        variant="default"
        icon="GitHub"
        className="w-full font-dmmono text-xs uppercase text-accent"
      >
        GitHub
      </Button>
    )
  )
}

export default GitHubButton
