/* eslint-disable */
// TODO: refactor this old code
"use client"

import { type FC, useEffect, useRef, useCallback } from "react"

import Script from "next/script"
import { signIn } from "next-auth/react"
import { toast } from "sonner"

import { Button } from "@/components/ui/button"

import { useLoginContext } from "../LoginContext"

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const GoogleButton: FC = () => {
  const googleLoginWrapperButtonRef = useRef(null)
  const { signingIn, setSigningIn } = useLoginContext()

  const signInWithGoogle = useCallback(
    async (credential: string, selectBy: string, clientID: string) => {
      setSigningIn("google")

      const response = await signIn("google", {
        credential,
        selectBy,
        clientID,
        redirect: false,
      })

      if (response?.status !== 200) {
        toast.error("Something went wrong whilst signing in with Google")
        setSigningIn(false)
      }
    },
    [setSigningIn],
  )

  const handleLogInWithGoogle = useCallback(
    (credential: string, selectBy: string, clientID: string) => {
      if (credential) {
        signInWithGoogle(credential, selectBy, clientID)
      }
    },
    [signInWithGoogle],
  )

  const handleGoogleLogin = useCallback(() => {
    if (!googleLoginWrapperButtonRef.current) {
      return
    }
    // @ts-ignore
    googleLoginWrapperButtonRef.current.click()
  }, [])

  const googleLoginCallback = useCallback(
    // @ts-ignore
    (response) => {
      const { credential, select_by, client_id } = response
      handleLogInWithGoogle(credential, select_by, client_id)
    },
    [handleLogInWithGoogle],
  )

  useEffect(() => {
    if (window) {
      // @ts-ignore
      window.handleCredentialResponse = (response) => {
        const { credential, select_by, client_id } = response
        handleLogInWithGoogle(credential, select_by, client_id)
      }
    }
  }, [handleLogInWithGoogle])

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.async = true
    script.onload = () => {
      // @ts-ignore
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        ux_mode: "popup",
        callback: googleLoginCallback,
      })
      const googleLoginWrapper = document.createElement("div")
      googleLoginWrapper.style.display = "none"
      googleLoginWrapper.classList.add("custom-google-button")

      document.body.appendChild(googleLoginWrapper)

      // @ts-ignore
      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      })
      // @ts-ignore
      googleLoginWrapperButtonRef.current =
        googleLoginWrapper.querySelector("div[role=button]")
    }
    document.body.appendChild(script)
  }, [googleLoginCallback])

  return (
    <>
      <Script src="https://accounts.google.com/gsi/client" async defer />
      {GOOGLE_CLIENT_ID && (
        <Button
          type="button"
          variant="default"
          onClick={handleGoogleLogin}
          onTouchStart={handleGoogleLogin}
          className="w-full font-dmmono text-xs uppercase text-accent"
          isLoading={signingIn === "google"}
          disabled={!!signingIn}
          icon="Google"
        >
          Google
        </Button>
      )}
    </>
  )
}

export default GoogleButton
